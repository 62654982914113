import React from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { addAuthentication } from '../components/addAuthentication';
import { ViewLayout } from '../components/layout';

function restrictedAPI({ data }) {
  let subscriptions = {};

  /**
   * Example of API Call using Authentication from the logged in user
   */
  Auth.currentAuthenticatedUser()
    .then((user) => {
      // Pull the email from the signInUserSession
      const userEmail = user.getSignInUserSession().getIdToken().payload.email;
      // Pull the ID Token used for authentication from the signInUserSession
      const idToken = user.getSignInUserSession().getIdToken();
      if (userEmail) {
        // Call the API Endpoint to list all subscriptions for the user, authenticated using the Authorization header
        axios
          .get(
            `https://api.ripenamwellcms.xyz/subscription/subscriptions/${userEmail}`,
            {
              headers: {
                Authorization: idToken.getJwtToken(),
              },
            }
          )
          .then((response) => {
            subscriptions = JSON.parse(response.data.body);
            // Dump output to console
            console.log(subscriptions);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
    .catch((err) => console.error(err));

  return (
    <ViewLayout>
      {subscriptions ? <div>Yay?</div> : <span>Unauthenticated</span>}
    </ViewLayout>
  );
}

export default addAuthentication(restrictedAPI);
